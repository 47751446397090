<template>
	<textarea ref="textarea" :value="value" @input="onInput" @focus="$emit('focus')" @blur="$emit('blur')" class="auto-resize-textarea" rows="1" placeholder="写回答……"></textarea>
</template>

<script>
export default {
	props: ['value'],
	methods: {
		onInput(event) {
			this.autoResize(event.target); // 调整高度
			this.$emit('input', event.target.value); // 更新父组件中的值
		},
		autoResize(element) {
			element.style.height = 'auto'; // 设置高度为 'auto' 重新计算
			element.style.height = element.scrollHeight + 'px'; // 使用 scrollHeight 设置 textarea 的高度
		}
	},
	mounted() {},
	updated() {
		this.autoResize(this.$refs.textarea);
	}
};
</script>
<style scoped>
.auto-resize-textarea {
	width: 100%;
	height: 0.48rem;
	border: none;
	background-color: transparent; /* 清除背景颜色 */
	border: none; /* 移除边框 */
	outline: none; /* 移除焦点时的轮廓 */
	resize: none; /* 禁用调整大小 */
	margin: 0;
	padding: 0;
}
</style>
