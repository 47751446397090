<template>
	<div class="container" v-show="pageShow">
		<van-sticky>
			<div class="row cb top">
				<div class="row cc left-view" @click="navHome()">
					<van-image class="home-image" :src="require('@/assets/home.png')" fit="cover" />
					<div class="row search-view">
						<div class="row cc main">
							<van-image class="search-img" :src="require('@/assets/search.png')" fit="cover" />
							<input type="text" placeholder="请输入要搜索的关键词" @keyup.enter="search" />
						</div>
					</div>
				</div>
				<div class="row cc right-view">
					<div class="row cc notice" @click="navMsg()">
						<van-image class="ding" :src="require('@/assets/ding.png')" fit="cover" />
						<div class="dot" v-show="currentuserInfo.have_unread == 1"></div>
					</div>
					<div class="row cc mine" @click="navUserHome()">
						<span class="row cc">我的</span>
						<van-image class="avatar" :src="currentuserInfo.avatar" fit="cover" round />
					</div>
				</div>
			</div>
		</van-sticky>

		<div class="col ask-header" ref="header">
			<div class="ask-title">{{ detail.title }}</div>
			<div class="row cb user-view" @click="navUserHome(askUserInfo.id)">
				<div class="row left">
					<van-image class="avatar" round :src="askUserInfo.avatar" fit="cover" />
					<div class="col info">
						<div class="row cc ">
							<span class="nickname">{{ askUserInfo.nickname }}</span>
							<span class="row cc auth">问题采纳率 {{ askUserInfo.que_accept_scale }}%</span>
						</div>
						<div class="row job" v-if="askUserInfo.is_cert == 1">
							<van-image class="auth-img" :src="require('@/assets/auth-1.png')" fit="cover" />
							<span>{{ askUserInfo.major_name }}</span>
						</div>
					</div>
				</div>
				<div class="row right cc" v-if="isMyAsk"><div class="btn row cc btn del" v-if="isMyAsk" @click.stop="delAsk()">删除问题</div></div>
				<div class="row right cc" v-else>
					<div class="btn row cc btn sub" @click.stop="tapSub()" v-if="askUserInfo.is_focus === '0'">
						<van-icon name="plus" color="#ffffff" size="12" />
						<span>关注</span>
					</div>
					<div class="btn row cc btn sub disable" @click.stop="tapSub()" v-else>
						<van-icon name="success" color="#999999" size="12" />
						<span>已关注</span>
					</div>
				</div>
			</div>
			<div class="ask-content">{{ detail.content }}</div>
			<div class="row ask-img">
				<div class="img-box" v-for="(imgV, imgK) in askImages" :key="imgK" @click="previewImg(imgK)"><van-image class="img" :src="imgV" fit="cover" /></div>
			</div>
			<div class="row tags">
				<div class="row cc item coin" v-if="detail.type == 1">
					<van-image class="icon-img" :src="require('@/assets/coin.png')" fit="cover" />
					<span>{{ detail.point }}</span>
				</div>
				<div class="row cc item redpacket" v-if="detail.type == 2">
					<van-image class="icon-img" :src="require('@/assets/redpacket.png')" fit="cover" />
					<span>{{ detail.amount }}元</span>
				</div>
				<div class="row cc item" v-for="(tVal, tKey) in detail.tags_name" :key="tKey">{{ tVal }}</div>
			</div>
			<div class="row cb date">
				<span>发布/更新于 {{ detail.update_time_text }}</span>
				<van-image class="ddd" :src="require('@/assets/ask/ddd.png')" fit="cover" @click="openReport()" />
			</div>
		</div>
		<div class="row cc notice-view answer-none" v-if="!detail.answer_num">
			<span>问题还没有答案</span>
			<div class="row cc link" @click="navReplyAsk()">
				<span>去添加第一个回答</span>
				<van-icon name="arrow" color="#03d3bc" />
			</div>
		</div>
		<div v-else-if="listShow">
			<van-pull-refresh v-model="refreshing" @refresh="onRefresh" :style="{ 'min-height': `300px` }">
				<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
					<div class="answer-page">
						<div class="row cb group-title">
							<span>{{ total }}个回答</span>
							<div class="row cb sort-switch">
								<div class="row cc item" :class="{ active: sortIndex == 0 }" @click="setSort(0)">最新</div>
								<div class="row cc item" :class="{ active: sortIndex == 1 }" @click="setSort(1)">热门</div>
							</div>
						</div>
						<template v-if="!accSub">
							<div class="col cc disable-view" @click="accountShow = true">
								<div class="row cc unfold">
									<span>展开剩余内容</span>
									<van-icon name="arrow-down" />
								</div>
								<van-button type="primary" round>关注公众号查看全部答案</van-button>
							</div>
						</template>

						<div class="col" :class="{ 'answer-hidden': !accSub }">
							<div class="answer-item" v-for="(item, index) in list" :key="index">
								<div class="row cb user-view" @click="navUserHome(item.user_id)">
									<div class="row left">
										<van-image class="avatar" :src="item.avatar" fit="cover" round />
										<div class="col info">
											<div class="row cc ">
												<span class="nickname">{{ item.nickname }}</span>
												<span class="row cc auth">回答被采纳 {{ item.acceptScale }}%</span>
											</div>
											<div class="row job" v-if="item.is_cert == 1">
												<van-image class="auth-img" :src="require('@/assets/auth-1.png')" fit="cover" />
												<span>{{ item.major_name }}</span>
											</div>
										</div>
									</div>
									<div class="row right cc">
										<div class="row cc btn disable" v-if="item.is_accept == 1">已采纳</div>
										<div class="row cc btn accept" v-if="isMyAsk && detail.reply_status != 3" @click.stop="acceptAnswer(index)">采纳答案</div>
									</div>
								</div>
								<div class="content" v-html="item.content" @click="openReply(index)"></div>
								<div class="row imgs-preview" v-if="item.imageArr.length">
									<div class="img-item" v-for="(img, imgIndex) in item.imageArr" @click="previewReply(item.imageArr, imgIndex)">
										<van-image width="68" height="68" class="share-icon" :src="$store.getters.oss(img)" fit="cover" />
									</div>
								</div>
								<div class="row operate">
									<div class="row cc item active" @click="agreeAnswer(index, 1)">
										<van-image class="icon" :src="require('@/assets/ask/zan.png')" fit="contain" />
										<span>赞同 {{ item.agree_num }}</span>
									</div>
									<div class="row cc item" @click="agreeAnswer(index, 0)">
										<van-image class="icon" :src="require('@/assets/ask/cai.png')" fit="contain" />
										<span>反对 {{ item.oppose_num }}</span>
									</div>
									<div class="row cc nav" @click="openReply(index)">
										<van-image class="icon" :src="require('@/assets/ask/comment.png')" fit="contain" />
										<span>回复</span>
									</div>
								</div>
								<div class="col reply-list" v-if="item.comment_list.length > 0">
									<div class="row reply-item" v-for="(value, key) in item.comment_list" :key="key" @click="openReply(index, value.id, value.nickname)">
										<div class="username">{{ value.nickname }}：</div>
										<div class="p">
											<span v-if="value.reply_nickname">回复 {{ value.reply_nickname }}</span>
											{{ value.content }}
										</div>
									</div>
								</div>
								<div class="row cb date">
									<span>发布/更新于 {{ item.update_time_text }}</span>
									<van-image class="ddd" :src="require('@/assets/ask/ddd.png')" fit="cover" @click="openReport(item.id)" />
								</div>
							</div>
						</div>
					</div>
				</van-list>
			</van-pull-refresh>
		</div>
		<div>
			<div class="row group-title">猜你想问</div>
			<template v-for="(gItem, gKey) in guessList">
				<reward :item="gItem" showTag></reward>
			</template>
			<div class="empty-view" v-if="guessList.length == 0"><van-empty description="暂无猜你想问" /></div>
		</div>
		<div>
			<div class="row group-title">悬赏问题</div>
			<template v-for="(rItem, rKey) in rewardList">
				<reward :item="rItem"></reward>
			</template>
			<div class="empty-view" v-if="rewardList.length == 0"><van-empty description="暂无悬赏问题" /></div>
		</div>
		<div class="row cc notice-view">
			<span>更多杏田问答</span>
			<div class="row cc link" @click="navHome()">
				<span>去首页</span>
				<van-icon name="arrow" color="#03d3bc" />
			</div>
		</div>
		<div :style="{ height: bottomHeight }"></div>
		<div class="fixed-bottom" ref="bottom">
			<div class="row">
				<div class="row btn-view"><auto-resize-textarea v-model="replayForm.content" @focus="showSubBtn = true"></auto-resize-textarea></div>
				<!-- <div class="row btn-view" @click="navReplyAsk()">写回答……</div> -->
				<div class="row menu-view">
					<div class="row cb" v-if="showSubBtn">
						<div style="margin: 0 12px;">
							<van-uploader :after-read="afterRead"><van-icon name="photo" color="#999" /></van-uploader>
						</div>
						<van-button type="primary" size="small" round @click="submitReply">提交回答</van-button>
					</div>
					<template v-else>
						<div class="col cc item" @click="tapCollect()" v-show="detail.is_focus === '1'">
							<van-image class="share-icon" :src="require('@/assets/xin-active.png')" fit="contain" />
							<span>取关问题</span>
						</div>
						<div class="col cc item" @click="tapCollect()" v-show="detail.is_focus === '0'">
							<van-image class="share-icon" :src="require('@/assets/xin.png')" fit="contain" />
							<span>关注问题</span>
						</div>
						<!-- <div class="col cc item" @click="openInvite()">
							<van-image class="share-icon" :src="require('@/assets/add-user.png')" fit="contain" />
							<span>邀请回答</span>
						</div> -->
						<div class="col cc item" @click="shareShow = true">
							<van-image class="share-icon" :src="require('@/assets/share.png')" fit="contain" />
							<span>分享</span>
						</div>
					</template>
				</div>
			</div>
			<div class="row imgs-preview" v-if="replayForm.imageArr.length">
				<div class="img-item" v-for="(item, index) in replayForm.imageArr">
					<van-image width="68" height="68" class="share-icon" :src="$store.getters.oss(item)" fit="cover" />
					<van-icon name="clear" color="#666" class="close-icon" @click="replayForm.imageArr.splice(index, 1)" />
				</div>
			</div>
		</div>
		<van-popup v-model="reportShow" position="bottom" round>
			<div class="col bottom-pop-form">
				<div class="title">{{ reportAnswerId ? '回答举报' : '问题举报' }}</div>
				<div class="row tags">
					<template v-for="(item, index) in reportType">
						<div class="row cc tag" :class="{ active: reportIndex !== '' && reportIndex === index }" @click="reportIndex = index">{{ item.name }}</div>
					</template>
				</div>
				<van-field
					:placeholder="reportAnswerId ? '请输入举报回答详情（5～120字）' : '请输入举报问题详情（5～120字）'"
					type="textarea"
					v-model="reportInput"
					maxlength="120"
				/>
				<div class="row cb bottom">
					<span class="tips">{{ reportInput.length }}/120</span>
					<div class="row cc btn" @click="reportSubmit()">提交</div>
				</div>
			</div>
		</van-popup>
		<van-popup v-model="replyShow" position="bottom" round>
			<div class="col bottom-pop-form">
				<div class="title" v-if="replyInfo.username">回复 {{ replyInfo.username }}</div>
				<div class="title" v-else>回复 {{ replyInfo.nickname }}</div>
				<van-field placeholder="你有什么看法" type="textarea" v-model="replyInput" maxlength="120" />
				<div class="row cb bottom">
					<span class="tips">{{ replyInput.length }}/120</span>
					<div class="row cc btn" @click="replySubmit()">提交</div>
				</div>
			</div>
		</van-popup>
		<van-popup v-model="inviteShow" position="bottom" round>
			<div class="invite-pop">
				<div class="row cb title">
					<span>邀请用户回答问题并关注ta</span>
					<span class="confirm" @click="inviteShow = false">完成</span>
				</div>
				<div class="list">
					<div class="row cb user-view" v-for="(item, index) in inviteList" :key="index">
						<div class="row left">
							<van-image class="avatar" :src="item.avatar" round fit="cover" />
							<div class="col info">
								<div class="row">
									<span class="nickname">{{ item.nickname }}</span>
								</div>
								<div class="row job" v-if="item.is_cert == 1">
									<van-image class="auth-img" :src="require('@/assets/auth-1.png')" fit="cover" />
									<span>{{ item.major_name }}</span>
								</div>
							</div>
						</div>
						<div class="row right cc">
							<div class="row cc share disable" v-if="item.is_invite == 1">已邀请</div>
							<div class="row cc share" v-else @click="confirmInvite(index)">邀请</div>
						</div>
					</div>
				</div>
			</div>
		</van-popup>
		<van-popup v-model="accountShow" :style="{ width: '335px', borderRadius: '10px' }">
			<div class="col cc popup-main">
				<img class="qr-top" :src="require('@/assets/qr-top.png')" />
				<div class="col cc main">
					<span class="tips-green">长按识别二维码关注“杏田问答”</span>
					<div class="qr">
						<img class="qr-bg" :src="require('@/assets/qr-bg.png')" />
						<img class="qr-img" :src="$store.getters.oss(setting.official_image)" />
					</div>
					<div class="more">查看更多问题&参与答题互动</div>
				</div>
				<div class="row cc close" @click.stop="accountShow = false"><van-icon name="cross" color="#ffffff" size="18" /></div>
			</div>
		</van-popup>
		<van-popup v-model="shareShow" class="share-arrow-pop" @click="shareShow = false"><img class="share-arrow" :src="require('@/assets/share-arrow.png')" /></van-popup>
		<div class="mask" v-if="showSubBtn" style="position: fixed;z-index: 98;top: 0;left: 0;width: 100vw;height: 100vh;" @click="showSubBtn = false"></div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { ImagePreview } from 'vant';
import que from '@/components/list/que.vue';
import answer from '@/components/list/answer.vue';
import reward from '@/components/list/reward.vue';
import AutoResizeTextarea from '@/components/AutoResizeTextarea/AutoResizeTextarea.vue';
export default {
	data() {
		return {
			pageShow: false,
			question_id: '',
			accountShow: false,
			reportShow: false,
			replyShow: false,
			replyInput: '',
			replyInfo: {},
			inviteShow: false,
			list: [],
			loading: false,
			finished: false,
			total: 0,
			page: 0,
			pageSize: 15,
			refreshing: false,
			headerHeight: '0px',
			bottomHeight: '0px',
			listShow: false,
			sortIndex: 0,
			setting: {},
			detail: {},
			askUserInfo: {},
			currentuserInfo: {},
			answerSort: ['time', 'agree'],
			guessList: {},
			rewardList: {},
			reportAnswerId: '',
			reportIndex: '',
			reportInput: '',
			reportType: [],
			inviteList: [],
			accSub: true,
			shareShow: false,
			replayForm: { content: '', imageArr: [] },
			showSubBtn: false
		};
	},
	components: { que, answer, reward, AutoResizeTextarea },
	computed: {
		askImages() {
			if (this.detail.image) {
				let imgs = this.detail.image.split(',');
				let result = imgs.map(e => this.$store.getters.oss(e));
				return result;
			} else {
				return [];
			}
		},
		isMyAsk() {
			return this.askUserInfo.id == this.currentuserInfo.id;
		}
	},
	async created() {
		if (!this.pageShow) {
			this.$toast.loading({
				duration: 0,
				message: '加载中...',
				forbidClick: true
			});
		}
		this.question_id = this.$route.query.id;
		// 后台设置
		let settingRes = this.$service.post('auth/setShow').then(res => {
			this.setting = res.data;
		});
		// 增加浏览记录
		let accessLog = this.$service.post('question/focusQue', { type: 2, id: this.question_id });
		// 问题详情
		let detailRes = this.getDetail();
		// 当前登录用户
		let currentuserInfo = this.$service.post('user_info/detail').then(res => {
			this.currentuserInfo = res.data;
		});
		// 猜你想问
		let guessList = this.$service.post('question/guessList', { question_id: this.question_id, limit: 2 }).then(res => {
			this.guessList = res.data;
		});
		// 悬赏问题
		let rewardList = this.$service.post('question/rewardList', { question_id: this.question_id, type: 1, limit: 2 }).then(res => {
			this.rewardList = res.data;
		});
		// 是否关注公众号
		let followRes = Promise.resolve();
		if (process.env.NODE_ENV === 'production') {
			followRes = this.$service.post('auth/is_follow').then(res => {
				this.accSub = res.data.is_follow == 1;
			});
		}
		Promise.all([settingRes, accessLog, detailRes, currentuserInfo, guessList, rewardList, followRes])
			.then(() => {
				// 题主用户
				return this.$service.post('user_info/detail', { user_id: this.detail.user_id }).then(res => {
					this.askUserInfo = res.data;
				});
			})
			.then(() => {
				this.pageShow = true;
				this.$toast.clear();
				this.$nextTick(() => {
					this.headerHeight = this.$refs['header'].offsetHeight + 'px';
					this.bottomHeight = this.$refs['bottom'].offsetHeight + 50 + 'px';
					let rate = this.detail.type == 1 ? '悬赏积分的100%' : '悬赏金额的80%';
					if (this.detail.dely_notice == 1) {
						this.$dialog
							.confirm({
								title: '延长悬赏24小时？',
								message: `24小时后仍未采纳，${rate}将平分给回答者`,
								confirmButtonColor: '#03d3bc'
							})
							.then(async () => {
								await this.$service.post('question/delayQue', { question_id: this.question_id });
								this.detail.is_focus = '0';
								this.$toast.success({
									message: '已延长悬赏'
								});
							})
							.catch(() => {});
					} else if (this.detail.endQue_notice == 1) {
						this.$dialog
							.confirm({
								title: '逾期未采纳，已自动结题',
								message: `${rate}将平分给所有回答者`,
								confirmButtonColor: '#03d3bc',
								confirmButtonText: '知道了',
								showCancelButton: false
							})
							.then(() => {})
							.catch(() => {});
					} else if (this.detail.drafts_notice == 1) {
						this.$dialog
							.confirm({
								title: '问题暂无人回答',
								message: `已回收至草稿箱，可编辑后重新发布`,
								confirmButtonColor: '#03d3bc',
								confirmButtonText: '知道了',
								showCancelButton: false
							})
							.then(() => {})
							.catch(() => {});
					}
				});
			});
		// 回答添加成功
		this.$app.$on('answerAddSuccess', () => {
			this.getDetail();
			this.onRefresh();
		});
	},
	mounted() {
		this.listShow = true;
		this.$nextTick(() => {
			this.onLoad();
		});
	},
	methods: {
		async submitReply() {
			await this.$service.post('reply/answer', { question_id: this.question_id, content: this.replayForm.content, image: this.replayForm.imageArr.join(',') });
			this.$toast.success({
				duration: 800,
				message: '回答成功'
			});
			setTimeout(() => {
				this.getDetail();
				this.onRefresh();
				this.replayForm = { content: '', imageArr: [] };
			}, 800);
		},
		afterRead(e) {
			console.log(typeof e);
			this.$service.upload(e.file).then(res => {
				this.replayForm.imageArr.push(res.data.url);
			});
		},
		async getDetail() {
			// 问题详情
			return this.$service.post('question/detail', { id: this.question_id }).then(res => {
				this.detail = res.data;
			});
		},
		async tapSub() {
			let item = this.askUserInfo;
			if (item.is_focus === '0') {
				await this.$service.post('question/focusUser', { focus_user_id: item.id });
				item.is_focus = '1';
			} else {
				await this.$service.post('question/delFocusUser', { focus_user_id: item.id });
				item.is_focus = '0';
			}
		},
		navMsg() {
			this.$router.push({
				path: '/msg'
			});
		},
		navUserHome(user_id = '') {
			if (user_id) {
				this.$router.push({
					path: '/user/home',
					query: {
						user_id
					}
				});
			} else {
				this.$router.push({
					path: '/user/home'
				});
			}
		},
		previewReply(images, startPosition) {
			ImagePreview({
				images: images.map(e => this.$store.getters.oss(e)),
				startPosition
			});
		},
		// 预览图片
		previewImg(index) {
			ImagePreview({
				images: this.askImages,
				startPosition: index
			});
		},
		async openInvite() {
			if (this.inviteList.length == 0) {
				this.$toast.loading({
					duration: 0,
					message: '加载中……'
				});
				// 邀请列表
				let inviteList = await this.$service.post('reply/inviteList', { question_id: this.question_id, limit: 100 });
				this.inviteList = inviteList.data;
				this.$toast.clear();
			}
			this.inviteShow = true;
		},
		// 确认邀请
		async confirmInvite(index) {
			await this.$service.post('reply/invite', { question_id: this.question_id, invite_user: this.inviteList[index].id });
			this.$set(this.inviteList[index], 'is_invite', 1);
			this.$toast.success({
				message: '邀请成功'
			});
		},
		openReply(index, reply_id = '', username = '') {
			this.replyInput = '';
			this.replyInfo = {
				id: this.list[index].id,
				nickname: this.list[index].nickname,
				reply_id,
				username
			};
			this.replyShow = true;
		},
		// 确认评论回答
		async replySubmit() {
			if (this.replyInput.trim().length < 5) {
				this.$toast('评论详情不得少于五个字');
			} else {
				try {
					await this.$service.post('reply/replyAnswer', {
						content: this.replyInput.trim(),
						answer_id: this.replyInfo.id,
						reply_id: this.replyInfo.reply_id
					});
					this.$toast.success({
						message: '评论成功'
					});
					this.onRefresh();
				} catch {}
				this.replyShow = false;
			}
		},
		// 确认举报
		async reportSubmit() {
			if (this.reportIndex === '') {
				this.$toast('请选择举报类型');
			} else if (this.reportInput.trim() == '') {
				this.$toast('请填写举报问题详情');
			} else if (this.reportInput.trim().length < 5) {
				this.$toast('举报详情不得少于五个字');
			} else {
				try {
					await this.$service.post('question/reportQuestion', {
						content: this.reportInput.trim(),
						report_type: this.reportType[this.reportIndex].id,
						type: this.reportAnswerId ? 2 : 1,
						question_id: this.reportAnswerId ? '' : this.question_id,
						answer_id: this.reportAnswerId || ''
					});
					this.$toast.success({
						message: '举报成功'
					});
				} catch {}
				this.reportShow = false;
			}
		},
		async openReport(id = '') {
			if (this.reportType.length == 0) {
				this.$toast.loading({
					duration: 0,
					message: '加载中……'
				});
				// 举报问题类型
				let reportType = await this.$service.post('question/reportType');
				this.reportType = reportType.data;
				this.$toast.clear();
			}
			this.reportAnswerId = id;
			this.reportIndex = '';
			this.reportInput = '';
			this.reportShow = true;
		},
		async tapCollect() {
			if (this.detail.is_focus === '1') {
				this.$dialog
					.confirm({
						title: '确定取消关注？',
						message: `你可能会错失许多精彩内容`,
						confirmButtonColor: '#03d3bc'
					})
					.then(async () => {
						await this.$service.post('question/delFocusQue', { type: 1, id: this.question_id });
						this.detail.is_focus = '0';
						this.$toast.success({
							message: '已取关问题'
						});
					})
					.catch(() => {});
			} else {
				await this.$service.post('question/focusQue', { type: 1, id: this.question_id });
				this.detail.is_focus = '1';
				this.$toast.success({
					message: '已关注问题'
				});
			}
		},
		navReplyAsk() {
			this.$router.push({
				path: '/ask/reply',
				query: {
					id: this.question_id
				}
			});
		},
		navHome() {
			this.$router.replace({
				path: '/'
			});
		},
		setSort(index) {
			this.sortIndex = index;
			this.onRefresh();
		},
		async onLoad() {
			console.log('onLoad');
			this.loading = true;
			this.page++;
			let res = await this.$service.post('reply/answerList', {
				id: this.question_id,
				sort: this.answerSort[this.sortIndex],
				page: this.page,
				pageSize: this.pageSize
			});
			this.total = res.data.total;
			if (res.data.data.length) {
				res.data.data.map(e => {
					e.imageArr = e.image ? e.image.split(',') : [];
				});
				this.list = this.list.concat(res.data.data);
				this.loading = false;
				if (res.data.data.length < this.pageSize) {
					this.finished = true;
				}
			} else {
				this.finished = true;
			}
			this.listShow = true;
			this.refreshing = false;
		},
		onRefresh() {
			console.log('onRefresh');
			this.page = 0;
			this.list = [];
			this.finished = false;
			this.refreshing = true;
			this.onLoad();
		},
		async agreeAnswer(index, is_agree) {
			let item = this.list[index];
			let res = await this.$service.post('reply/isAgree', { answer_id: item.id, is_agree });
			this.$toast.success({
				message: is_agree == 1 ? '已赞同' : '已反对'
			});
			this.list[index].agree_num = res.data.agree_num;
			this.list[index].oppose_num = res.data.oppose_num;
		},

		acceptAnswer(index) {
			let item = this.list[index];
			let rate = this.detail.type == 1 ? '悬赏积分的100%' : '悬赏金额的80%';
			this.$dialog
				.confirm({
					title: '确定采纳该答案？',
					// message: `“${item.nickname}”将获得${rate}`,
					message: `“${item.nickname}”的回答将被推荐`,
					confirmButtonColor: '#03d3bc'
				})
				.then(async () => {
					await this.$service.post('question/acceptAnswer', { answer_id: item.id });
					await this.getDetail();
					this.$toast.success({
						message: '采纳成功'
					});
					this.onRefresh();
				})
				.catch(() => {});
		},
		delAsk() {
			this.$dialog
				.confirm({
					title: '提示',
					message: '您确认要删除问题？'
				})
				.then(async () => {
					await this.$service.post('question/delete', { question_id: this.question_id });
					this.$toast.success({
						message: '删除成功',
						duration: 2000
					});
					setTimeout(() => {
						this.$router.replace({
							path: '/user/home'
						});
					}, 2000);
				})
				.catch(() => {});
		}
	}
};
</script>

<style scoped lang="less">
@import './detail.less';
</style>
